<template>
  <div class="user-management-container">
    <nxp-search
      :config-list="searchConfigList"
      @clickSearch="clickSearch"
      @resetForm="resetForm"
    />
    <!-- 表格 -->
    <select-table
      :accessories="nxpTabelAccessories"
      only-key-id="id"
      :table-columns="tableColumns"
      :table-data="tableData"
      :table-loading="tableLoading"
      :w-theight="320"
      @fetchData="fetchData"
    />
  </div>
</template>

<script>
import { querySmsDataList } from "@/project/eztree/baseApi/commonApi";
export default {
  name: "Logger",
  components: {},
  data() {
    return {
      queryForm: {},
      searchConfigList: [
        {
          type: "text",
          showName: false,
          model: "",
          param: "phone",
          label: "用户手机号",
        },
        {
          type: "daterange",
          showName: false,
          model: "",
          param: "date",
          label: "日期",
         
        },
      ],
      tableLoading: false,
      tableData: [],
      nxpTabelAccessories: {
        pageType: "",
        pageNo: 1,
        pageSize: 15,
        total: 0,
        height: this.$baseTableHeight(1),
      },
    };
  },
  computed: {
    tableColumns() {
      let columns = [
        {
          label: "手机号",
          prop: "phone",
          tooltip: true,
        },
        { label: "ip", prop: "ip" },
        { label: "操作", prop: "action" },
        {
          label: "验证码",
          prop: "status",
          tooltip: true,
        },
        {
          label: "结果",
          prop: "result",
        },
        {
          label: "创建时间",
          prop: "created_at",
        },
      ];
      return columns;
    },
  },
  mounted() {},
  created() {},
  methods: {
    // 初始化
    async initQuery() {
      this.tableLoading = true;
      let params = Object.assign({}, this.queryForm);
      params.page = this.nxpTabelAccessories.pageNo;
      params.page_size = this.nxpTabelAccessories.pageSize;
      const { date } = params;
      if (date) {
        params.start_time = date[0];
        params.end_time = date[1];
        console.log("params", params, date);
        delete params.date;
      }
      querySmsDataList(params).then(({ code, data }) => {
        if (code == "200") {
          this.tableData = (data && data.list) || [];
          this.nxpTabelAccessories.total = data.total_count;
        }
        this.tableLoading = false;
      });
    },
    // 分页查询
    fetchData(val) {
      this.nxpTabelAccessories.pageNo = val.pageNo;
      this.nxpTabelAccessories.pageSize = val.pageSize;
      this.initQuery();
    },
    // 模糊查询
    clickSearch(val) {
      this.queryForm = val;
      this.nxpTabelAccessories.pageNo = 1;
      this.initQuery();
    },
    resetForm() {
      this.queryForm = {};
      this.nxpTabelAccessories.pageNo = 1;
      this.initQuery();
    },
    handleEdit(row, action) {
      this.$refs["edit"].showEdit(row, action);
    },
  },
};
</script>
